.star {
  display: inline-block;
  transition: all 0.5s ease;
}

.star:hover {
  transform: scale(1.5);
}

.star:hover:not(:first-child) {
  margin-left: 0.2rem;
}

.star:hover:not(:last-child) {
  margin-right: 0.2rem;
}
