.createButton {
  display: block;
  margin-bottom: 1rem;
  width: 100%;
}

.createButton button {
  cursor: pointer;
  width: inherit;
}

.createButton svg {
  font-size: 1.2rem;
  margin-right: 0.5rem;
}
