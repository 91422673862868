.grid {
  display: grid;
  grid-gap: 2rem;
  grid-template-areas:
    'main'
    'aside';
  padding: 0 1rem;
}

@media (min-width: 768px) {
  .grid {
    grid-template-areas: 'main aside';
    grid-template-columns: 3fr 1fr;
  }
}

@media (min-width: 992px) {
  .grid {
    grid-template-areas: 'main aside';
    grid-template-columns: 5fr 1fr;
  }
}

.grid main {
  grid-area: main;
}

.grid aside {
  grid-area: aside;
}
