.book {
  display: flex;
  padding: 0.75rem;
  width: 100%;
}

.book h5 {
  flex-grow: 1;
  margin: 0;
}
